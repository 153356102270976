import {
    GET_PAYMENT_INFO,
    GET_PAYMENT_INFO_SUCCESS,
    GET_PAYMENT_INFO_FAILED,

    SUBMIT_PAYMENT_INFO,
    SUBMIT_PAYMENT_INFO_SUCCESS,
    SUBMIT_PAYMENT_INFO_FAILED,
} from '../../constants/actionTypes';

const INIT_STATE = {
    loading: false,
    submittingForm: false,
    error: null,
    paymentInfo: {
        abn: '',
        bsb: '',
        accountNo: '',
        accountName: ''
    }
};

const PaymentInfoReducer = (state = INIT_STATE, action) => {
    switch (action.type) {
        case SUBMIT_PAYMENT_INFO:
            return { ...state, error: null, submittingForm: true };
        case SUBMIT_PAYMENT_INFO_SUCCESS:
            return { ...state, paymentInfo: action.payload, submittingForm: false };
        case SUBMIT_PAYMENT_INFO_FAILED:
            return { ...state, error: action.payload, submittingForm: false };

        case GET_PAYMENT_INFO:
            return { ...state, loading: true };
        case GET_PAYMENT_INFO_SUCCESS:
            return { ...state, paymentInfo: action.payload, loading: false, error: null };
        case GET_PAYMENT_INFO_FAILED:
            return { ...state, error: action.payload, loading: false };
        
                
        default: return { ...state };
    }
}

export default PaymentInfoReducer;