import {
    GET_REFERRALS,
    GET_REFERRALS_SUCCESS,
    GET_REFERRALS_FAILED,

    SUBMIT_REFERRAL_FORM,
    SUBMIT_REFERRAL_FORM_SUCCESS,
    SUBMIT_REFERRAL_FORM_FAILED,
} from '../../constants/actionTypes';

const INIT_STATE = {
    loading: false,
    referrals: [],
    submittingForm: false,
    error: null,
    currentReferral: null
};

const UserReducer = (state = INIT_STATE, action) => {
    switch (action.type) {
        case GET_REFERRALS:
            return { ...state, loading: true };
        case GET_REFERRALS_SUCCESS:
            return { ...state, referrals: action.payload.data, total: action.payload.total, loading: false, error: null };
        case GET_REFERRALS_FAILED:
            return { ...state, error: action.payload, loading: false };

        case SUBMIT_REFERRAL_FORM:
            return { ...state, error: null, submittingForm: true };
        case SUBMIT_REFERRAL_FORM_SUCCESS:
            return { ...state, error: null, currentReferral: action.payload, submittingForm: false };
        case SUBMIT_REFERRAL_FORM_FAILED:
            return { ...state, error: action.payload, submittingForm: false };
            
        default: return { ...state };
    }
}

export default UserReducer;