import {
    GET_DASHBOARD_DATA,
    GET_DASHBOARD_DATA_SUCCESS,
    GET_DASHBOARD_DATA_FAILED,
} from '../../constants/actionTypes'; 


/** GET ALL */
export const getDashboardData = () => ({
    type: GET_DASHBOARD_DATA
});

export const getDashboardDataSuccess = (data) => ({
    type: GET_DASHBOARD_DATA_SUCCESS,
    payload: data
});

export const getDashboardDataFailed = (error) => ({
    type: GET_DASHBOARD_DATA_FAILED,
    payload: error
});
