import React from 'react';
import { Redirect } from "react-router-dom";
import { Route } from 'react-router-dom';

import { isUserAuthenticated, getLoggedInUser } from './helpers/authUtils';
import { NonAuthLayout   } from './Layouts';

// lazy load all the views
const Dashboard = React.lazy(() => import('./pages/dashboards/'));
const Referrals = React.lazy(() => import('./pages/referrals/'));
const Transactions = React.lazy(() => import('./pages/transactions/'));
const Tickets = React.lazy(() => import('./pages/tickets/'));
const TicketDetails = React.lazy(() => import('./pages/ticketDetails/'));
const GetPaid = React.lazy(() => import('./pages/getPaid/'));

const Profile = React.lazy(() => import('./pages/profile/'));
const ChangePassword = React.lazy(() => import('./pages/changePassword/'));

// auth
const Landing = React.lazy(() => import('./pages/landing'));
const Login = React.lazy(() => import('./pages/auth/Login'));
const Logout = React.lazy(() => import('./pages/auth/Logout'));
const ForgetPassword = React.lazy(() => import('./pages/account/ForgetPassword'));
const ResetPassword = React.lazy(() => import('./pages/account/ResetPassword'));

const Share = React.lazy(() => import('./pages/share'));

// handle auth and authorization

const PrivateRoute = ({ component: Component, permission, ...rest }) => (
  <Route {...rest} render={props => {
    const isAuthTokenValid = isUserAuthenticated();
    if (!isAuthTokenValid) {
      // not logged in so redirect to login page with the return url
      return <Redirect to={{ pathname: '/login', state: { from: props.location } }} />
    }

    const loggedInUser = getLoggedInUser();

    // check if route is restricted by permission
    if (permission && (loggedInUser.permissions[permission] === false)) {
      // role not authorised so redirect to home page
      return <Redirect to={{ pathname: '/dashboard' }} />
    }

    // authorised so return component
    return <Component {...props} />
  }} />
)

const routes = [
  // auth and account
  { path: '/landing', name: 'Landing', layout: NonAuthLayout, component: Landing, route: Route },
  { path: '/login', name: 'Login', component: Login, route: Route },
  { path: '/logout', name: 'Logout', component: Logout, route: Route },
  { path: '/forget-password', name: 'Forget Password', component: ForgetPassword, route: Route },
  { path: '/reset-password', name: 'Reset Password', component: ResetPassword, route: Route },

  { path: '/share', name: 'Share', layout: NonAuthLayout, component: Share, route: Route },


  // other pages
  { path: '/dashboard', name: 'Dashboard', component: Dashboard, route: PrivateRoute, },
  { path: '/profile', name: 'Profile', component: Profile, route: PrivateRoute },
  { path: '/change-password', name: 'ChangePassword', component: ChangePassword, route: PrivateRoute},
  { path: '/referrals', name: 'Referrals', component: Referrals, route: PrivateRoute, },
  { path: '/transactions', name: 'Transactions', component: Transactions, route: PrivateRoute, },
  { path: '/get-paid', name: 'GetPaid', component: GetPaid, route: PrivateRoute, },
  { path: '/tickets', exact: true, name: 'Tickets', component: Tickets, route: PrivateRoute, },
  { path: '/tickets/:id', exact: true, name: 'TicketDetails', component: TicketDetails, route: PrivateRoute, },
  {
    path: "/",
    exact: true,
    component: () => <Redirect to="/login" />,
    route: PrivateRoute
  },
  
]

export { routes, PrivateRoute };