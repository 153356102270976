import {
    GET_PAYMENT_INFO,
    GET_PAYMENT_INFO_SUCCESS,
    GET_PAYMENT_INFO_FAILED,

    SUBMIT_PAYMENT_INFO,
    SUBMIT_PAYMENT_INFO_SUCCESS,
    SUBMIT_PAYMENT_INFO_FAILED,
} from '../../constants/actionTypes'; 


/** SUBMIT FORM */
export const submitPaymentInfo = (paymentInfo) => ({
    type: SUBMIT_PAYMENT_INFO,
    payload: paymentInfo
});

export const submitPaymentInfoSuccess = (paymentInfo) => ({
    type: SUBMIT_PAYMENT_INFO_SUCCESS,
    payload: paymentInfo
});

export const submitPaymentInfoFailed = (error) => ({
    type: SUBMIT_PAYMENT_INFO_FAILED,
    payload: error
});


/** GET CURRENT */
export const getPaymentInfo = () => ({
    type: GET_PAYMENT_INFO,
});

export const getPaymentInfoSuccess = (paymentInfo) => ({
    type: GET_PAYMENT_INFO_SUCCESS,
    payload: paymentInfo
});

export const getPaymentInfoFailed = (error) => ({
    type: GET_PAYMENT_INFO_FAILED,
    payload: error
});