import { all, call, fork, put, takeEvery } from 'redux-saga/effects';

import { fetchJSON, apiRootEndpoint } from '../../helpers';
import {
    GET_REFERRALS,
    SUBMIT_REFERRAL_FORM, 
} from '../../constants/actionTypes';


import {
    getAllReferralsSuccess,
    getAllReferralsFailed,

    submitReferralFormSuccess,
    submitReferralFormFailed,
} from './actions';

const apiUrl = `${apiRootEndpoint}/api/v1/affiliate`;


function* getAllReferrals({ payload: { offset, limit, filter } }) {
    const options = {
        method: 'GET',
    };

    try {
        let url = `${apiUrl}/referrals?offset=${offset}&limit=${limit}`;
        if(filter.query) {
            url = `${url}&query=${filter.query}`
        }
        if(filter.branchId) {
            url = `${url}&branchId=${filter.branchId}`
        }
        const {total, data} = yield call(fetchJSON, url, options);
        yield put(getAllReferralsSuccess({total, data}));
    } catch (error) {
        let message;
        switch (error.status) {
            case 500: message = 'Internal Server Error'; break;
            default: message = error;
        }
        yield put(getAllReferralsFailed(message));
    }
}


function* submitReferral({ payload: referral }) {
    const options = {
        body: JSON.stringify(referral),
        method: 'POST',
        headers: { 'Content-Type': 'application/json' }
    };

    try {
        let url = `${apiUrl}/referrals`; 
        const response = yield call(fetchJSON, url, options);
        if(response.status === 'OK') {
            yield put(submitReferralFormSuccess(response.data));
        }
        else {
            yield put(submitReferralFormFailed(response.message));
        }
    } catch (error) {
        let message;
        switch (error.status) {
            case 500: message = 'Internal Server Error'; break;
            default: message = error;
        }
        yield put(submitReferralFormFailed(message));
    }
}

export function* watchGetAllReferrals() {
    yield takeEvery(GET_REFERRALS, getAllReferrals);
}

export function* watchSubmitReferralForm() {
    yield takeEvery(SUBMIT_REFERRAL_FORM, submitReferral);
}


function* referralSaga() {
    yield all([
        fork(watchGetAllReferrals),
        fork(watchSubmitReferralForm),
    ]);
}

export default referralSaga;