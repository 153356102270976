/* AUTH */
export const LOGIN_USER = 'LOGIN_USER';
export const LOGIN_USER_SUCCESS = 'LOGIN_USER_SUCCESS';
export const LOGIN_USER_FAILED = 'LOGIN_USER_FAILED';

export const REGISTER_USER = 'REGISTER_USER';
export const REGISTER_USER_SUCCESS = 'REGISTER_USER_SUCCESS';
export const REGISTER_USER_FAILED = 'REGISTER_USER_FAILED';

export const LOGOUT_USER = 'LOGOUT_USER';

export const FORGET_PASSWORD = 'FORGET_PASSWORD';
export const FORGET_PASSWORD_SUCCESS = 'FORGET_PASSWORD_SUCCESS';
export const FORGET_PASSWORD_FAILED = 'FORGET_PASSWORD_FAILED';

export const RESET_PASSWORD = 'RESET_PASSWORD';
export const RESET_PASSWORD_SUCCESS = 'RESET_PASSWORD_SUCCESS';
export const RESET_PASSWORD_FAILED = 'RESET_PASSWORD_FAILED';

/* CURRENT USER  */
export const GET_CURRENT_USER = 'GET_CURRENT_USER';
export const GET_CURRENT_USER_SUCCESS = 'GET_CURRENT_USER_SUCCESS';
export const GET_CURRENT_USER_FAILED = 'GET_CURRENT_USER_FAILED';

/* USER  */
export const GET_USERS = 'GET_USERS';
export const GET_USERS_SUCCESS = 'GET_USERS_SUCCESS';
export const GET_USERS_FAILED = 'GET_USERS_FAILED';

export const SUBMIT_USER_FORM = 'SUBMIT_USER_FORM';
export const SUBMIT_USER_FORM_SUCCESS = 'SUBMIT_USER_FORM_SUCCESS';
export const SUBMIT_USER_FORM_FAILED = 'SUBMIT_USER_FORM_FAILED';

export const DELETE_USER = 'DELETE_USER';
export const DELETE_USER_SUCCESS = 'DELETE_USER_SUCCESS';
export const DELETE_USER_FAILED = 'DELETE_USER_FAILED';

/* AUTH USER  */
export const SUBMIT_CHANGE_PASSWORD_FORM = 'SUBMIT_CHANGE_PASSWORD_FORM';
export const SUBMIT_CHANGE_PASSWORD_FORM_SUCCESS = 'SUBMIT_CHANGE_PASSWORD_FORM_SUCCESS';
export const SUBMIT_CHANGE_PASSWORD_FORM_FAILED = 'SUBMIT_CHANGE_PASSWORD_FORM_FAILED';

/* REFERRAL  */
export const GET_REFERRALS = 'GET_REFERRALS';
export const GET_REFERRALS_SUCCESS = 'GET_REFERRALS_SUCCESS';
export const GET_REFERRALS_FAILED = 'GET_REFERRALS_FAILED';

export const SUBMIT_REFERRAL_FORM = 'SUBMIT_REFERRAL_FORM';
export const SUBMIT_REFERRAL_FORM_SUCCESS = 'SUBMIT_REFERRAL_FORM_SUCCESS';
export const SUBMIT_REFERRAL_FORM_FAILED = 'SUBMIT_REFERRAL_FORM_FAILED';


/* COUNTRY  */
export const GET_COUNTRY = 'GET_COUNTRY';
export const GET_COUNTRY_SUCCESS = 'GET_COUNTRY_SUCCESS';
export const GET_COUNTRY_FAILED = 'GET_COUNTRY_FAILED';

export const SUBMIT_COUNTRY_FORM = 'SUBMIT_COUNTRY_FORM';
export const SUBMIT_COUNTRY_FORM_SUCCESS = 'SUBMIT_COUNTRY_FORM_SUCCESS';
export const SUBMIT_COUNTRY_FORM_FAILED = 'SUBMIT_COUNTRY_FORM_FAILED';

export const DELETE_COUNTRY = 'DELETE_COUNTRY';
export const DELETE_COUNTRY_SUCCESS = 'DELETE_COUNTRY_SUCCESS';
export const DELETE_COUNTRY_FAILED = 'DELETE_COUNTRY_FAILED';

/* SERVICE  */
export const GET_SERVICES = 'GET_SERVICES';
export const GET_SERVICES_SUCCESS = 'GET_SERVICES_SUCCESS';
export const GET_SERVICES_FAILED = 'GET_SERVICES_FAILED';

/* TRANSACTION  */
export const GET_TRANSACTIONS = 'GET_TRANSACTIONS';
export const GET_TRANSACTIONS_SUCCESS = 'GET_TRANSACTIONS_SUCCESS';
export const GET_TRANSACTIONS_FAILED = 'GET_TRANSACTIONS_FAILED';

export const SUBMIT_TRANSACTION_FORM = 'SUBMIT_TRANSACTION_FORM';
export const SUBMIT_TRANSACTION_FORM_SUCCESS = 'SUBMIT_TRANSACTION_FORM_SUCCESS';
export const SUBMIT_TRANSACTION_FORM_FAILED = 'SUBMIT_TRANSACTION_FORM_FAILED';


/* DASHBOARD  */
export const GET_DASHBOARD_DATA = 'GET_DASHBOARD_DATA';
export const GET_DASHBOARD_DATA_SUCCESS = 'GET_DASHBOARD_DATA_SUCCESS';
export const GET_DASHBOARD_DATA_FAILED = 'GET_DASHBOARD_DATA_FAILED';

/* PAYMENT INFO  */
export const GET_PAYMENT_INFO = 'GET_PAYMENT_INFO';
export const GET_PAYMENT_INFO_SUCCESS = 'GET_PAYMENT_INFO_SUCCESS';
export const GET_PAYMENT_INFO_FAILED = 'GET_PAYMENT_INFO_FAILED';

export const SUBMIT_PAYMENT_INFO = 'SUBMIT_PAYMENT_INFO';
export const SUBMIT_PAYMENT_INFO_SUCCESS = 'SUBMIT_PAYMENT_INFO_SUCCESS';
export const SUBMIT_PAYMENT_INFO_FAILED = 'SUBMIT_PAYMENT_INFO_FAILED';

/* TICKETS  */
export const GET_TICKETS = 'GET_TICKETS';
export const GET_TICKETS_SUCCESS = 'GET_TICKETS_SUCCESS';
export const GET_TICKETS_FAILED = 'GET_TICKETS_FAILED';

export const SUBMIT_TICKET_FORM = 'SUBMIT_TICKET_FORM';
export const SUBMIT_TICKET_FORM_SUCCESS = 'SUBMIT_TICKET_FORM_SUCCESS';
export const SUBMIT_TICKET_FORM_FAILED = 'SUBMIT_TICKET_FORM_FAILED';

export const DELETE_TICKET = 'DELETE_TICKET';
export const DELETE_TICKET_SUCCESS = 'DELETE_TICKET_SUCCESS';
export const DELETE_TICKET_FAILED = 'DELETE_TICKET_FAILED';

export const GET_CURRENT_TICKET = 'GET_CURRENT_TICKET';
export const GET_CURRENT_TICKET_SUCCESS = 'GET_CURRENT_TICKET_SUCCESS';
export const GET_CURRENT_TICKET_FAILED = 'GET_CURRENT_TICKET_FAILED';

/* TICKET COMMENTS  */
export const GET_TICKET_COMMENTS = 'GET_TICKET_COMMENTS';
export const GET_TICKET_COMMENTS_SUCCESS = 'GET_TICKET_COMMENTS_SUCCESS';
export const GET_TICKET_COMMENTS_FAILED = 'GET_TICKET_COMMENTS_FAILED';

export const SUBMIT_TICKET_COMMENT_FORM = 'SUBMIT_TICKET_COMMENT_FORM';
export const SUBMIT_TICKET_COMMENT_FORM_SUCCESS = 'SUBMIT_TICKET_COMMENT_FORM_SUCCESS';
export const SUBMIT_TICKET_COMMENT_FORM_FAILED = 'SUBMIT_TICKET_COMMENT_FORM_FAILED';

export const DELETE_TICKET_COMMENT = 'DELETE_TICKET_COMMENT';
export const DELETE_TICKET_COMMENT_SUCCESS = 'DELETE_TICKET_COMMENT_SUCCESS';
export const DELETE_TICKET_COMMENT_FAILED = 'DELETE_TICKET_COMMENT_FAILED';
