import {
    GET_TICKET_COMMENTS,
    GET_TICKET_COMMENTS_SUCCESS,
    GET_TICKET_COMMENTS_FAILED,

    SUBMIT_TICKET_COMMENT_FORM,
    SUBMIT_TICKET_COMMENT_FORM_SUCCESS,
    SUBMIT_TICKET_COMMENT_FORM_FAILED,

    DELETE_TICKET_COMMENT,
    DELETE_TICKET_COMMENT_SUCCESS,
    DELETE_TICKET_COMMENT_FAILED,
} from '../../constants/actionTypes';


/** GET ALL */
export const getAllTicketComments = ({offset, limit, filter = {}}) => ({
    type: GET_TICKET_COMMENTS,
    payload: { offset, limit, filter }
});

export const getAllTicketCommentsSuccess = ({total, data}) => ({
    type: GET_TICKET_COMMENTS_SUCCESS,
    payload: {total, data}
});

export const getAllTicketCommentsFailed = (error) => ({
    type: GET_TICKET_COMMENTS_FAILED,
    payload: error
});

/** SUBMIT FORM */
export const submitTicketCommentForm = (country) => ({
    type: SUBMIT_TICKET_COMMENT_FORM,
    payload: country
});

export const submitTicketCommentFormSuccess = (item) => ({
    type: SUBMIT_TICKET_COMMENT_FORM_SUCCESS,
    payload: item
});

export const submitTicketCommentFormFailed = (error) => ({
    type: SUBMIT_TICKET_COMMENT_FORM_FAILED,
    payload: error
});

/** DELETE FORM */
export const deleteTicketComment = (id) => ({
    type: DELETE_TICKET_COMMENT,
    payload: id
});

export const deleteTicketCommentSuccess = (id) => ({
    type: DELETE_TICKET_COMMENT_SUCCESS,
    payload: id
});

export const deleteTicketCommentFailed = (error) => ({
    type: DELETE_TICKET_COMMENT_FAILED,
    payload: error
});
