import { combineReducers } from 'redux';
import Auth from './auth/reducers';
import User from './user/reducers';
import AuthUser from './authUser/reducers';
import Country from './country/reducers';
import Referral from './referral/reducers';
import Service from './service/reducers';
import Transaction from './transaction/reducers';
import Dashboard from './dashboard/reducers';
import PaymentInfo from './paymentInfo/reducers';
import Ticket from './ticket/reducers';
import TicketComment from './ticketComment/reducers';

export default combineReducers({
    Auth,
    User,
    AuthUser,
    Country,
    Referral,
    Service,
    Transaction,
    Dashboard,
    PaymentInfo,
    Ticket,
    TicketComment
});