import { all, call, fork, put, takeEvery } from 'redux-saga/effects';
import cogoToast from 'cogo-toast';

import { fetchJSON, apiRootEndpoint } from '../../helpers';
import {
    GET_PAYMENT_INFO, 
    SUBMIT_PAYMENT_INFO,
} from '../../constants/actionTypes';


import {
    submitPaymentInfoSuccess,
    submitPaymentInfoFailed,

    getPaymentInfoSuccess,
    getPaymentInfoFailed,
} from './actions';

const apiUrl = `${apiRootEndpoint}/api/v1/affiliate/payment-info`;

function* submitPaymentInfo({ payload: paymentInfo }) {
    const options = {
        body: JSON.stringify(paymentInfo),
        method: paymentInfo.id ? 'PUT' :  'POST',
        headers: { 'Content-Type': 'application/json' }
    };

    try {
        let url = `${apiUrl}`;
        if(paymentInfo.id) {
            url = `${apiUrl}/${paymentInfo.id}`;
        }
        const response = yield call(fetchJSON, url, options);
        
        if(response.status === 'OK') {
            
            yield put(submitPaymentInfoSuccess(response.data));
        }
        else {
            cogoToast.error(response.message);
            yield put(submitPaymentInfoFailed(response.message));
        }
    } catch (error) {
        let message;
        switch (error.status) {
            case 500: message = 'Internal Server Error'; break;
            default: message = error;
        }
        cogoToast.error(message);
        yield put(submitPaymentInfoFailed(message));
    }
}

function* getPaymentInfo() {
    const options = {
        method: 'GET',
    };
    
    try {
        let url = `${apiUrl}`;
        
        const result = yield call(fetchJSON, url, options);
        
        yield put(getPaymentInfoSuccess(result.data));
    } catch (error) {
        let message;
        switch (error.status) {
            case 500: message = 'Internal Server Error'; break;
            default: message = error;
        }
        cogoToast.error(message);
        yield put(getPaymentInfoFailed(message));
    }
}


export function* watchPaymentInfo() {
    yield takeEvery(GET_PAYMENT_INFO, getPaymentInfo);
}
export function* watchSubmitPaymentInfoForm() {
    yield takeEvery(SUBMIT_PAYMENT_INFO, submitPaymentInfo);
}

function* paymentInfoSaga() {
    yield all([
        fork(watchPaymentInfo),
        fork(watchSubmitPaymentInfoForm),
    ]);
}

export default paymentInfoSaga;