import {

    SUBMIT_USER_FORM,
    SUBMIT_USER_FORM_SUCCESS,
    SUBMIT_USER_FORM_FAILED,

    GET_CURRENT_USER,
    GET_CURRENT_USER_SUCCESS,
    GET_CURRENT_USER_FAILED
} from '../../constants/actionTypes'; 



/** SUBMIT FORM */
export const submitUserForm = (user) => ({
    type: SUBMIT_USER_FORM,
    payload: user
});

export const submitUserFormSuccess = (user) => ({
    type: SUBMIT_USER_FORM_SUCCESS,
    payload: user
});

export const submitUserFormFailed = (error) => ({
    type: SUBMIT_USER_FORM_FAILED,
    payload: error
});



/** GET CURRENT */
export const getCurrentUser = (id) => ({
    type: GET_CURRENT_USER,
    payload: id
});

export const getCurrentUserSuccess = (user) => ({
    type: GET_CURRENT_USER_SUCCESS,
    payload: user
});

export const getCurrentUserFailed = (error) => ({
    type: GET_CURRENT_USER_FAILED,
    payload: error
});