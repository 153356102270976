import { getLoggedInUser } from './authUtils'; 


const fetchJSON = (url, options = {}) => {
    const user = getLoggedInUser();
    if(user) {
        options.headers =  {
            ...options.headers,
            'x-access-token': user.token
          };
    } 
    return fetch(url, options)
        .then(response => {
            if (!response.status === 200) {
                throw response.json();
            }
            return response.json();
        })
        .then(json => {
            return json;
        })
        .catch(error => { throw error });
}

const getApiEndPoint = () => {
  const { hostname } = window.location;
  let serverUrl;
  
  switch (hostname) {
    case 'localhost':
      serverUrl = 'http://localhost:8182';
      break;
    case 'affiliate.experteducation.com':
      serverUrl = 'https://api.eevsalumni.com';
      break;
    default:
  }

  return serverUrl;
}

const getAffiliateUrl = () => {
  const { hostname } = window.location;
  let url;

  switch (hostname) {
    case 'localhost':
      url = 'http://localhost:3002';
      break;
    case 'affiliate.experteducation.com':
      url = 'https://affiliate.experteducation.com';
      break;
    default:
  }

  return url;
}

const apiRootEndpoint = getApiEndPoint();

export { fetchJSON, apiRootEndpoint, getAffiliateUrl  };