import { all } from 'redux-saga/effects';
import authSaga from './auth/saga';
import userSaga from './user/saga';
import authUser from './authUser/saga';
import countrySaga from './country/saga';
import referralSaga from './referral/saga';
import serviceSaga from './service/saga';
import transactionSaga from './transaction/saga';
import dashboardSaga from './dashboard/saga';
import paymentInfoSaga from './paymentInfo/saga';
import ticketSaga from './ticket/saga';
import ticketCommentSaga from './ticketComment/saga';


export default function* rootSaga(getState) {
    yield all([
        authSaga(),
        userSaga(),
        authUser(),
        countrySaga(),
        referralSaga(),
        serviceSaga(),
        transactionSaga(),
        dashboardSaga(),
        paymentInfoSaga(),
        ticketSaga(),
        ticketCommentSaga()
    ]);
}
