import { all, call, fork, put, takeEvery } from 'redux-saga/effects';
import { fetchJSON, apiRootEndpoint } from '../../helpers';
import {
    GET_DASHBOARD_DATA, 
} from '../../constants/actionTypes';


import {
    getDashboardDataSuccess,
    getDashboardDataFailed,
} from './actions';

const apiUrl = `${apiRootEndpoint}/api/v1/affiliate/dashboard`;

function* getDashboardData() {
    const options = {
        method: 'GET',
    };

    try {
        const {data} = yield call(fetchJSON, apiUrl, options);
        yield put(getDashboardDataSuccess(data));
    } catch (error) {
        let message;
        switch (error.status) {
            case 500: message = 'Internal Server Error'; break;
            default: message = error;
        }
        yield put(getDashboardDataFailed(message));
    }
}


export function* watchGetDatabaseData() {
    yield takeEvery(GET_DASHBOARD_DATA, getDashboardData);
}

function* saga() {
    yield all([
        fork(watchGetDatabaseData),
    ]);
}

export default saga;