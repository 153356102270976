import {
    GET_TICKETS,
    GET_TICKETS_SUCCESS,
    GET_TICKETS_FAILED,

    SUBMIT_TICKET_FORM,
    SUBMIT_TICKET_FORM_SUCCESS,
    SUBMIT_TICKET_FORM_FAILED,

    DELETE_TICKET,
    DELETE_TICKET_SUCCESS,
    DELETE_TICKET_FAILED,

    GET_CURRENT_TICKET,
    GET_CURRENT_TICKET_SUCCESS,
    GET_CURRENT_TICKET_FAILED
} from '../../constants/actionTypes';


/** GET ALL */
export const getAllTickets = ({offset, limit, filter = {}}) => ({
    type: GET_TICKETS,
    payload: { offset, limit, filter }
});

export const getAllTicketsSuccess = ({total, data}) => ({
    type: GET_TICKETS_SUCCESS,
    payload: {total, data}
});

export const getAllTicketsFailed = (error) => ({
    type: GET_TICKETS_FAILED,
    payload: error
});

/** SUBMIT FORM */
export const submitTicketForm = (country) => ({
    type: SUBMIT_TICKET_FORM,
    payload: country
});

export const submitTicketFormSuccess = (item) => ({
    type: SUBMIT_TICKET_FORM_SUCCESS,
    payload: item
});

export const submitTicketFormFailed = (error) => ({
    type: SUBMIT_TICKET_FORM_FAILED,
    payload: error
});

/** DELETE FORM */
export const deleteTicket = (id) => ({
    type: DELETE_TICKET,
    payload: id
});

export const deleteTicketSuccess = (id) => ({
    type: DELETE_TICKET_SUCCESS,
    payload: id
});

export const deleteTicketFailed = (error) => ({
    type: DELETE_TICKET_FAILED,
    payload: error
});

/** GET CURRENT */
export const getCurrentTicket = (id) => ({
    type: GET_CURRENT_TICKET,
    payload: id
});

export const getCurrentTicketSuccess = (item) => ({
    type: GET_CURRENT_TICKET_SUCCESS,
    payload: item
});

export const getCurrentTicketFailed = (error) => ({
    type: GET_CURRENT_TICKET_FAILED,
    payload: error
});