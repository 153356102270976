import {
    GET_DASHBOARD_DATA,
    GET_DASHBOARD_DATA_SUCCESS,
    GET_DASHBOARD_DATA_FAILED,
} from '../../constants/actionTypes';

const INIT_STATE = {
    loading: false,
    error: null,
    dashboardData: null
};

const UserReducer = (state = INIT_STATE, action) => {
    switch (action.type) {
        case GET_DASHBOARD_DATA:
            return { ...state, loading: true };
        case GET_DASHBOARD_DATA_SUCCESS:
            return { ...state, dashboardData: action.payload, loading: false, error: null };
        case GET_DASHBOARD_DATA_FAILED:
            return { ...state, error: action.payload, loading: false };

        default: return { ...state };
    }
}

export default UserReducer;