import { all, call, fork, put, takeEvery } from 'redux-saga/effects';
import { Cookies } from "react-cookie";
import cogoToast from 'cogo-toast';

import { fetchJSON, apiRootEndpoint } from '../../helpers';
import {
    SUBMIT_USER_FORM,
    GET_CURRENT_USER,
} from '../../constants/actionTypes';


import {
    submitUserFormSuccess,
    submitUserFormFailed,

    getCurrentUserSuccess,
    getCurrentUserFailed
} from './actions';

const apiUrl = `${apiRootEndpoint}/api/v1/affiliate/affiliates`;

const updateCurrentUser = (user) => {
    const cookies = new Cookies(); 
    const currentUser = cookies.get("user");
    const currentUserObject =  currentUser ? (typeof(currentUser) == 'object'? currentUser: JSON.parse(currentUser)) : null;
    if(currentUserObject.id === user.id) {
        cookies.set("user", JSON.stringify(
            {
                ...currentUserObject, 
                firstName: user.firstName,
                lastName: user.lastName,
                email: user.email,
                phone: user.phone
            }), { path: "/" });
        
        window.location.reload();
        
    }
}

function* submitUser({ payload: user }) {
    const options = {
        body: JSON.stringify(user),
        method: user.id ? 'PUT' :  'POST',
        headers: { 'Content-Type': 'application/json' }
    };

    try {
        let url = `${apiUrl}`;
        if(user.id) {
            url = `${apiUrl}/${user.id}`;
        }
        const response = yield call(fetchJSON, url, options);
        updateCurrentUser(response.data);
        if(response.status === 'OK') {
            cogoToast.success('Profile updated successfully!');
            yield put(submitUserFormSuccess(response.data));
        }
        else {
            yield put(submitUserFormFailed(response.message));
        }
    } catch (error) {
        let message;
        switch (error.status) {
            case 500: message = 'Internal Server Error'; break;
            default: message = error;
        }
        yield put(submitUserFormFailed(message));
    }
}

function* getCurrentUser({ payload: id }) {
    const options = {
        method: 'GET',
    };
    
    try {
        let url = `${apiUrl}/${id}`;
       
        const user = yield call(fetchJSON, url, options);
        yield put(getCurrentUserSuccess(user.data));
    } catch (error) {
        let message;
        switch (error.status) {
            case 500: message = 'Internal Server Error'; break;
            default: message = error;
        }
        yield put(getCurrentUserFailed(message));
    }
}

export function* watchSubmitUserForm() {
    yield takeEvery(SUBMIT_USER_FORM, submitUser);
}

export function* watchGetCurrentUser() {
    yield takeEvery(GET_CURRENT_USER, getCurrentUser);
}

function* userSaga() {
    yield all([
        fork(watchSubmitUserForm),
        fork(watchGetCurrentUser),
    ]);
}

export default userSaga;