import {
    GET_REFERRALS,
    GET_REFERRALS_SUCCESS,
    GET_REFERRALS_FAILED,

    SUBMIT_REFERRAL_FORM,
    SUBMIT_REFERRAL_FORM_SUCCESS,
    SUBMIT_REFERRAL_FORM_FAILED
} from '../../constants/actionTypes'; 



/** GET ALL */
export const getAllReferrals = ({offset, limit, filter}) => ({
    type: GET_REFERRALS,
    payload: { offset, limit, filter }
});

export const getAllReferralsSuccess = ({total, data}) => ({
    type: GET_REFERRALS_SUCCESS,
    payload: {total, data}
});

export const getAllReferralsFailed = (error) => ({
    type: GET_REFERRALS_FAILED,
    payload: error
});


/** SUBMIT CHANGE PASSWORD FORM */
export const submitReferralForm = (referral) => ({
    type: SUBMIT_REFERRAL_FORM,
    payload: referral
});

export const submitReferralFormSuccess = (referral) => ({
    type: SUBMIT_REFERRAL_FORM_SUCCESS,
    payload: referral
});

export const submitReferralFormFailed = (error) => ({
    type: SUBMIT_REFERRAL_FORM_FAILED,
    payload: error
});