import {
    SUBMIT_USER_FORM,
    SUBMIT_USER_FORM_SUCCESS,
    SUBMIT_USER_FORM_FAILED,

    GET_CURRENT_USER,
    GET_CURRENT_USER_SUCCESS,
    GET_CURRENT_USER_FAILED
} from '../../constants/actionTypes';

const INIT_STATE = {
    loading: false,
    users: [],
    submittingForm: false,
    error: null,
    deleting: false,
    currentUser: null
};

const UserReducer = (state = INIT_STATE, action) => {
    switch (action.type) {
        
        case SUBMIT_USER_FORM:
            return { ...state, error: null, submittingForm: true };
        case SUBMIT_USER_FORM_SUCCESS:
            const alreadyExists = state.users.find((user) => user.id === action.payload.id);
            let users;
            let total = state.total;
            if (!alreadyExists) {
                users = [action.payload, ...state.users];
                total += 1;
            } else {
                users = state.users.map((user) => {
                if (user.id === action.payload.id) {
                  return action.payload;
                }
                return user;
              });
            }
            return { ...state, total,  users, submittingForm: false };
        case SUBMIT_USER_FORM_FAILED:
            return { ...state, error: action.payload, submittingForm: false };

        case GET_CURRENT_USER:
            return { ...state, loading: true };
        case GET_CURRENT_USER_SUCCESS:
            return { ...state, currentUser: action.payload, loading: false, error: null };
        case GET_CURRENT_USER_FAILED:
            return { ...state, error: action.payload, loading: false };
        
                
        default: return { ...state };
    }
}

export default UserReducer;