import {
    GET_TICKET_COMMENTS,
    GET_TICKET_COMMENTS_SUCCESS,
    GET_TICKET_COMMENTS_FAILED,

    SUBMIT_TICKET_COMMENT_FORM,
    SUBMIT_TICKET_COMMENT_FORM_SUCCESS,
    SUBMIT_TICKET_COMMENT_FORM_FAILED,

    DELETE_TICKET_COMMENT,
    DELETE_TICKET_COMMENT_SUCCESS,
    DELETE_TICKET_COMMENT_FAILED,
} from '../../constants/actionTypes';

const INIT_STATE = {
    loading: false,
    ticketComments: [],
    submittingForm: false,
    error: null,
    deleting: false,
};

const CountryReducer = (state = INIT_STATE, action) => {
    switch (action.type) {
        case GET_TICKET_COMMENTS:
            return { ...state, loading: true };
        case GET_TICKET_COMMENTS_SUCCESS: 
            return { ...state, ticketComments: action.payload.data, total: action.payload.total,  loading: false, error: null };
        case GET_TICKET_COMMENTS_FAILED:
            return { ...state, error: action.payload, loading: false };

        case SUBMIT_TICKET_COMMENT_FORM:
            return { ...state, error: null, submittingForm: true };
        case SUBMIT_TICKET_COMMENT_FORM_SUCCESS:
            const alreadyExists = state.ticketComments.find((item) => item.id === action.payload.id);
            let ticketComments;
            let total = state.total;
            if (!alreadyExists) {
                ticketComments = [...state.ticketComments, action.payload];
                total += 1;
            } else {
                ticketComments = state.ticketComments.map((item) => {
                if (item.id === action.payload.id) {
                  return action.payload;
                }
                return item;
              });
            }
            return { ...state, total,  ticketComments, submittingForm: false };
        case SUBMIT_TICKET_COMMENT_FORM_FAILED:
            return { ...state, error: action.payload, submittingForm: false };

        case DELETE_TICKET_COMMENT:
            return { ...state, error: null, deleting: true };
        case DELETE_TICKET_COMMENT_SUCCESS:
            return { ...state, total: state.total - 1, ticketComments: state.ticketComments.filter(item => item.id !== action.payload) , deleting: false };
        case DELETE_TICKET_COMMENT_FAILED:
            return { ...state, error: action.payload, deleting: false };
        default: return { ...state };
    }
}

export default CountryReducer;